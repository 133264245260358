import { inView, animate } from "motion";
import Alpine from "alpinejs";
import intersect from "@alpinejs/intersect";
import Navigation from "./Blocks/Header/navigation";
import simpleParallax from 'simple-parallax-js';
import "./main.css";
import videojs from "video.js";
import "video.js/./dist/video-js.css";

Alpine.plugin(intersect);

window.Alpine = Alpine;
Alpine.start();

const domReady = () => {
  Navigation();
  initModules();
  initScrollAnimations();

  const videoNodes = document.querySelectorAll('video');
  videoNodes.forEach(videoNode => {
    videoNode.classList.add('video-js');

    const vidOptions = {
      autoplay: 'any',
      controls: true,
      fluid: true
    };

    const player = videojs(videoNode, vidOptions);
  });

  // Button smooth scroll if hash found
  const buttonLinkNodes = document.querySelectorAll('.block-cyan-btn');
  if (buttonLinkNodes.length) {
    buttonLinkNodes.forEach(button => {
      button.addEventListener('click', e => {
        if (button.hash) {
          const targetEl = document.querySelector(button.hash);

          if (targetEl) {
            e.preventDefault();

            targetEl.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
          }
        }
      })
    });
  }
};

function initModules() {
  const cardNodes = document.querySelectorAll(".giantpeach-card");
  if (cardNodes.length) {
    import("./Blocks/Card/card.js").then(({ default: Card }) => {
      cardNodes.forEach((el, index) => {
        Card(el, index);
      });
    });
  }

  const teamCarousel = document.querySelectorAll('.team-carousel');
  if (teamCarousel.length) {
    import("./Blocks/TeamCarousel/teamCarousel.js").then(({ default: TeamCarousel }) => {
      TeamCarousel();
    });
  }

  const logoSliderNodes = document.querySelectorAll('[data-logoslider]');
  if (logoSliderNodes.length) {
    import("./Blocks/LogoSlider/logoSlider.js", {prefetch: true}).then(({ default: LogoSlider }) => {
      logoSliderNodes.forEach(slider => {
        LogoSlider(slider);
      });
    });
  }

  const testimonialSliderNodes = document.querySelectorAll(".giantpeach-testimonialslider");
  if (testimonialSliderNodes.length) {
    import("./Blocks/TestimonialSlider/testimonialSlider.js").then(({default: TestimonialSlider}) => {
      testimonialSliderNodes.forEach(slider => {
        TestimonialSlider(slider);
      });
    });
  }

}

function initScrollAnimations() {
  let transitionableBlocks = document.querySelectorAll(".transition-block");
  transitionableBlocks.forEach(el => {
    el.style.opacity = 0;
    el.style.transform = 'translateY(60px)';
  });

  let inViewStop = inView(transitionableBlocks, ({ target }) => {
    animate(
      target,
      { opacity: 1, transform: "none" },
      { delay: target.dataset.delay ? target.dataset.delay : 0.2, duration: 0.5, easing: [0.25, 0.1, 0.25, 1] },
    );

    return () => {
      animate(
        target,
        { opacity: 0, transform: "translateY(60px)" },
        { duration: 0.5, easing: [0.25, 0.1, 0.25, 1] },
      );
    };
  });

  document.addEventListener("block:load", () => {
    transitionableBlocks = document.querySelectorAll(".transition-block");
    inViewStop(); // Override previous listeners
    inViewStop = inView(transitionableBlocks, ({ target }) => {
      animate(
        target,
        { opacity: 1, transform: "none" },
        { delay: 0.2, duration: 0.5, easing: [0.25, 0.1, 0.25, 1] },
      );

      return () => {
        animate(
          target,
          { opacity: 0, transform: "translateY(60px)" },
          { duration: 0.5, easing: [0.25, 0.1, 0.25, 1] },
        );
      };
    });
  });


  // fix btn transition / wrap with div
  const cardContentBtnElements = document.querySelectorAll('.giantpeach-card-content > .block-cyan-btn, .giantpeach-cardinner-content > .block-cyan-btn');
  cardContentBtnElements.forEach(el => {
    const tempDiv = document.createElement('div');
    tempDiv.classList.add('block-cyan-btn-wrapper');
    el.after(tempDiv);
    el.classList.add('w-fit');
    tempDiv.appendChild(el);
  });
  // fix end

  const cardContentElements = document.querySelectorAll('.giantpeach-card-content > .wp-block-heading, .giantpeach-card-content > p, .giantpeach-card-content > .block-cyan-btn-wrapper, .giantpeach-cardinner-content > .wp-block-heading, .giantpeach-cardinner-content > p, .giantpeach-cardinner-content > .block-cyan-btn-wrapper');
  cardContentElements.forEach(el => {
    el.dataset.fadeto = getComputedStyle(el)['opacity'];
    el.style.opacity = 0;
    el.style.transform = 'translateY(60px)';
  });

  inView(cardContentElements, ({ target }) => {
    animate(
      target,
      { opacity: target.dataset.fadeto ? target.dataset.fadeto : 1, transform: "none" },
      { delay: 0.2, duration: 0.5, easing: [0.25, 0.1, 0.25, 1] },
    );

    return () => {
      animate(
        target,
        { opacity: 0, transform: "translateY(60px)" },
        { duration: 0.5, easing: [0.25, 0.1, 0.25, 1] },
      );
    };
  });

  // Parallax
  const parallaxNodes = document.querySelectorAll(".parallax-wrapper");
  if (parallaxNodes.length) {
    parallaxNodes.forEach(el => {

      let options = {
        overflow: true,
        scale: el.dataset?.scale ?? 1.2,
        orientation: el.dataset?.orientation ?? 'up'
      };

      new simpleParallax(el, options);
    })
  }
}

document.addEventListener("DOMContentLoaded", domReady);
